import React, { useRef, useState, useLayoutEffect } from 'react';
import { Link } from 'gatsby';
import { gsap, ScrollTrigger } from "gsap/all";
import { motion } from "framer-motion";

import Styleswrapper from '../components/StylesWrapper';
import PageMeta from '../components/blocks/helmet/PageMeta';
import Mainheader from '../components/MainHeader';
import Footer from '../components/Footer';
import '../styles/about.css';

// images
import profilePhoto from '../images/about/nihar_bhagat_profile.jpg';

// icons
import TeapotIcon from '../images/icons/teapot.png';
import PhotoFrameIcon from '../images/icons/photo_frame.png';
import BrightnessIcon from '../images/icons/brightness.png';
import PenIcon from '../images/icons/pen.png';
import DangerFlagIcon from '../images/icons/danger_flag.png';
import CatIcon from '../images/icons/cat.png';
import TrainIcon from '../images/icons/train.png';
import LibertyIcon from '../images/icons/liberty.png';
import LadderIcon from '../images/icons/ladder.png';
import JapanIcon from '../images/icons/japan.png';
import SatelliteIcon from '../images/icons/satellite.png';
import EducationIcon from '../images/icons/education.png';
import FleurIcon from '../images/icons/fleur.png';
import LogIcon from '../images/icons/log.png';

// influences artwork
import MontaigneArt from '../images/about/influences/montaigne.svg';
import EssayArt from '../images/about/influences/essay.svg';
import SethArt from '../images/about/influences/seth.svg';
import ReidArt from '../images/about/influences/reid.svg';
import HansArt from '../images/about/influences/hans.svg';
import JaiminArt from '../images/about/influences/jaimin.svg';
import GoogleArt from '../images/about/influences/google.svg';
import BasecampArt from '../images/about/influences/basecamp.svg';
import DieterArt from '../images/about/influences/dieter.svg';
import TagoreArt from '../images/about/influences/tagore.svg';
import DanArt from '../images/about/influences/dan.svg';
import PaulArt from '../images/about/influences/paul.svg';
import RichardArt from '../images/about/influences/richard.svg';
import JohnArt from '../images/about/influences/john.svg';
import DagnyArt from '../images/about/influences/dagny.svg';
import CaseyArt from '../images/about/influences/casey.svg';


const About = () => {
    gsap.registerPlugin(ScrollTrigger);
    const [profileLoaded, setProfileLoaded] = useState(false);

    const colors = {
        "green": '#053320',
        "blue": '#04192E',
        "purple": '#150429',
        "maroon1": '#0D0006',
        "maroon2": '#33051E',
        "black": '#00070D',
    };

    const introRef = useRef();
    const journeyRef = useRef();
    const influencesRef = useRef();
    const outroRef = useRef();

    // page bg gradient animation
    useLayoutEffect(() => {
        const introBlock = introRef.current;
        const influencesBlock = influencesRef.current;

        let bgMasterTl = gsap.timeline({
            scrollTrigger: {
                trigger: introBlock,
                start: "top top",
                endTrigger: influencesBlock,
                end: "bottom top",
                scrub: true,
            }
        }).to(".master-bg-block", {
            "--bg-1": colors.blue,
        }).to(".master-bg-block", {
            "--bg-1": colors.purple,
        }).to(".master-bg-block", {
            "--bg-1": colors.maroon1,
            "--bg-2": colors.maroon2,
            "--bg-3": colors.maroon2,
            "--bg-4": colors.maroon2,
            "--bg1-stop": "65%",
            "--bg2-stop": "100%",
            "--bg3-stop": "100%",
            "--bg4-stop": "100%",
        });

        // cleanup
        return () => {
            bgMasterTl.kill();
        }
    }, [colors.blue, colors.purple, colors.maroon1, colors.maroon2]);


    // PROFILE IMAGE WRAPPER
    const profileWrapperVariants = {
        initial: {
            opacity: 0,
            scale: 0,
        },
        animate: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 0.4,
                ease: "circOut",
                delay: 0.4,
            },
        },
    };

    // PROFILE IMAGE
    const profileImageVariants = {
        initial: {
            opacity: 0,
            scale: 2,
        },
        animate: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 0.4,
                ease: "circOut",
                delay: 0.4,
            },
        },
    };

    // HERO COPY
    const introHeroCopyVariants = {
        initial: {
            opacity: 0,
            x: "-3%",
        },
        animate: {
            opacity: 1,
            x: "0%",
            transition: {
                duration: 0.8,
                ease: "circOut",
                delay: 0.4,
            },
        },
    };

    // GOLDEN SEPARATOR
    const introHeroSeparatorVariants = {
        initial: {
            scaleX: 0,
            transformOrigin: "left",
        },
        animate: {
            scaleX: 1,
            transformOrigin: "left",
            transition: {
                duration: 1,
                ease: "circOut",
                delay: 0.8,
            },
        },
    };

    // TEAPOT ICON 🫖
    const introHeroIconVariants = {
        initial: {
            y: "25%",
            opacity: 0,
        },
        animate: {
            y: "0%",
            opacity: 1,
            transition: {
                duration: 0.6,
                ease: "circOut",
                delay: 0.8,
            },
        },
    };

    // MAIN COPY
    const introHeroMainCopyVariants = {
        initial: {
            y: "-25%",
            opacity: 0,
        },
        animate: {
            y: "0%",
            opacity: 1,
            transition: {
                duration: 0.6,
                ease: "circOut",
                delay: 0.8,
            },
        },
    };


    useLayoutEffect(() => {
        let $ = gsap.utils.selector(journeyRef);

        let journeyMasterTl = gsap.timeline({
            scrollTrigger: {
                trigger: journeyRef.current,
                start: "top center",
                id: "journey-tl",
            }
        });

        let learningsCopyTl = gsap.timeline({
            defaults: {
                duration: 0.4,
                ease: "circOut",
            },
        });

        let copyElements = [$(".about-journey-learnings .about-journey-learnings-copy"), $(".about-journey-practice-copy p:nth-child(1)")];
        let iconElements = [$(".about-journey-learnings .about-journey-learnings-icon"), $(".about-journey-practice-icon")];

        learningsCopyTl.from(copyElements, {
            opacity: 0,
            y: "50%",
            stagger: {
                each: 0.2,
                ease: "circ.out"
            },
        }).from(iconElements, {
            opacity: 0,
            scale: 0,
            stagger: {
                each: 0.2,
                ease: "circ".out
            },
        }, 0);


        let pathTl = gsap.timeline({
        });

        pathTl.to($("#Path_420"), {
            strokeDashoffset: 0,
            duration: 2,
            ease: "circInOut",
        });


        let milestoneDotsTl = gsap.timeline({});
        milestoneDotsTl.from($(".milestone-dot"), {
            scale: 0,
            opacity: 0,
            duration: 0.25,
            transformOrigin: "center",
        });


        let milestoneCopyTl = gsap.timeline({});
        milestoneCopyTl.from($(".milestone-copy"), {
            opacity: 0,
            duration: 1,
            stagger: 0.1,
        });


        let practiceCopyTl = gsap.timeline({});
        practiceCopyTl.from($(".about-journey-practice-copy p:nth-child(2)"), {
            opacity: 0,
            y: "10%",
            duration: 1,
            ease: "circOut",
        });

        journeyMasterTl.add(learningsCopyTl, "learningsCopy");
        journeyMasterTl.add(pathTl, "path");
        journeyMasterTl.add(milestoneDotsTl, "milestoneDots");
        journeyMasterTl.add(milestoneCopyTl, "milestoneCopy");
        journeyMasterTl.add(practiceCopyTl, "practiceCopy");

        // cleanup
        return () => {
            journeyMasterTl.kill();
        }
    }, []);

    useLayoutEffect(() => {
        let $ = gsap.utils.selector(influencesRef);
        let masterTl = gsap.timeline({
            scrollTrigger: {
                trigger: influencesRef.current,
                start: "top center",
            }
        });

        let influencesTl = gsap.timeline({});

        // cat icon
        influencesTl.from($(".about-influences-icon:nth-child(1)"), {
            opacity: 0,
            skewX: -5,
            x: "50%",
            duration: 1,
            ease: "power4.out",
        });

        // first name
        influencesTl.from($(".about-influences-copy:nth-of-type(1) .influencer-name"), {
            x: "3%",
            duration: 1,
            ease: "power4.out",
        }, 0.1);

        // first name - letters
        influencesTl.from($(".about-influences-copy:nth-of-type(1) .influencer-name span"), {
            opacity: 0,
            x: "10%",
            stagger: 0.035,
            duration: 1,
            ease: "power4.out",
        }, 0.1);

        // first name copy
        influencesTl.from($(".about-influences-copy:nth-of-type(1) .influencer-copy span"), {
            opacity: 0,
            y: "-10%",
            stagger: 0.4,
            duration: 1.4,
            ease: "power4.out",
        }, 1);

        let copyElements = [$(".about-influences-copy:not(:nth-of-type(1))")];
        let iconElements = [$(".about-influences-icon:not(:nth-of-type(1))")];

        influencesTl.from(copyElements, {
            opacity: 0,
            y: "-10%",
            stagger: {
                each: 0.1,
            },
            duration: 2,
            ease: "power4.out",
        }, 2.5);

        influencesTl.from(iconElements, {
            opacity: 0,
            y: "-10%",
            stagger: {
                each: 0.1,
            },
            duration: 2,
            ease: "power4.out",
        }, 2.5);

        influencesTl.to(".influences-art-group", {
            opacity: 1,
            scale: 1,
            stagger: 1,
            duration: 4,
            ease: "circ.out",
        }, 5);

        masterTl.add(influencesTl, "influences");

        // cleanup
        return () => {
            masterTl.kill();
        }

    }, []);

    useLayoutEffect(() => {
        let $ = gsap.utils.selector(outroRef);
        let masterTl = gsap.timeline({
            scrollTrigger: {
                trigger: outroRef.current,
                start: "top center",
            }
        });

        let outroCopyTl = gsap.timeline({});

        outroCopyTl.from($(".about-outro-copy"), {
            opacity: 0,
            duration: 2,
            ease: "power4.out",
        })
            .from($(".line"), {
                scaleX: 0,
                duration: 3,
                ease: "power4.out"
            }, 1)
            .from($(".about-outro-filter p:nth-child(2)"), {
                y: "-100%",
                opacity: 0,
                duration: 2,
                ease: "power4.out"
            }, 1.1);

        masterTl.add(outroCopyTl, "outroCopy");


        return () => {
            masterTl.kill();
        }
    }, []);


    return (
        <Styleswrapper
            bgColor1={'#053320'}
            bgColor2={'#00070D'}
            bg1Stop="0%"
            bg2Stop="35%"
            noise="true"
        >
            <PageMeta
                theme={'#053320'}
                title="About my journey — Nihar Bhagat"
                description="An aside on my skills, journey, and influences"
                image="../images/social/about/default.jpg"
                twitterImage="../images/social/about/twitter.jpg"
            />

            <Mainheader
                bgColor1={'#053320'}
                bgColor2={'#00070D'}
                bg1Stop="0%"
                bg2Stop="35%"
                noise="true"
            />
            <div className="about-container">

                {/* I  N  T  R  O */}
                <section className="about-intro" id='intro' ref={introRef}>
                    <div className="about-intro-wrapper">
                        <div className="about-intro-hero">
                            <motion.div
                                className="about-intro-hero-profile"
                                variants={profileWrapperVariants}
                                initial="initial"
                                animate={profileLoaded ? "animate" : "initial"}
                            >
                                <motion.img src={profilePhoto}
                                    alt="Nihar Bhagat"
                                    draggable="false"
                                    loading='eager'
                                    variants={profileImageVariants}
                                    initial="initial"
                                    animate={profileLoaded ? "animate" : "initial"}
                                    onLoad={() => { setProfileLoaded(true) }}
                                />
                            </motion.div>
                            <div className="about-intro-hero-copy">
                                <motion.p
                                    variants={introHeroCopyVariants}
                                    initial="initial"
                                    animate={profileLoaded ? "animate" : "initial"}
                                >
                                    My current skillset is at the intersection of Design and (actual) Branding.<br /><br /> Using that my Practice allows me to create entities that have a clear&nbsp;vision.
                                </motion.p>
                            </div>
                        </div>
                        <div className="about-intro-main">
                            <motion.div
                                className="about-intro-main-icon"
                                variants={introHeroIconVariants}
                                initial="initial"
                                animate={profileLoaded ? "animate" : "initial"}
                            >
                                <img src={TeapotIcon}
                                    alt="🫖"
                                    draggable="false"
                                    loading='eager'
                                />
                            </motion.div>
                            <div className="about-intro-main-copy">
                                <motion.div
                                    className="separator-line"
                                    variants={introHeroSeparatorVariants}
                                    initial="initial"
                                    animate={profileLoaded ? "animate" : "initial"}
                                ></motion.div>
                                <motion.div
                                    className="copy-grid"
                                    variants={introHeroMainCopyVariants}
                                    initial="initial"
                                    animate={profileLoaded ? "animate" : "initial"}
                                >
                                    <h1>My name is <span>Nihar&nbsp;Bhagat</span></h1>
                                    <h2>
                                        Previously, Creative Director of Slangbusters, a Design studio that made long-lasting Brands.
                                    </h2>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* J  O  U  R  N  E  Y */}
                <section className="about-journey" id='journey' ref={journeyRef}>
                    <div className="about-journey-wrapper">
                        <div className="about-journey-learnings">
                            <div className="about-journey-learnings-icon">
                                <img src={PenIcon}
                                    alt="✒️"
                                    draggable="false"
                                    loading='lazy'
                                />
                            </div>
                            <p className='about-journey-learnings-copy'>What I learnt from <span>Standup Comedy</span></p>
                            <div className="about-journey-learnings-icon">
                                <img src={PhotoFrameIcon}
                                    alt="🎴"
                                    draggable="false"
                                    loading='lazy'
                                />
                            </div>
                            <p className='about-journey-learnings-copy'>I applied to <span>Graphic Design</span></p>
                            <div className="about-journey-learnings-icon">
                                <img src={BrightnessIcon}
                                    alt="🔅"
                                    draggable="false"
                                    loading='lazy'
                                />
                            </div>
                            <p className='about-journey-learnings-copy'>and then the river flowed towards <span>Branding</span></p>
                        </div>
                        <div className="about-journey-timeline">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1389" height="673" viewBox="0 0 1389 673">
                                <g transform="translate(-506 -480)">
                                    <text className="cls-1 milestone-copy down" style={{ transform: "translate(539px, 743px)" }}><tspan x="-28.734" y="0">Enrolled in</tspan><tspan x="-32.916" y="15">Engineering</tspan></text>
                                    <text className="cls-1 milestone-copy down" style={{ transform: "translate(624px, 774px)" }}><tspan x="7.769" y="12">Started writing for </tspan><tspan x="13.002" y="27">standup comedy</tspan></text>
                                    <text className="cls-1 milestone-copy up" style={{ transform: "translate(761px, 655px)" }}><tspan x="12.937" y="12">Job as a Graphic </tspan><tspan x="35.661" y="27">Designer</tspan></text>
                                    <text className="cls-1 milestone-copy down" style={{ transform: "translate(839px, 813px)" }}><tspan x="3.126" y="12">Quit Engineering </tspan><tspan x="39.547" y="27"></tspan></text>
                                    <text className="cls-1 milestone-copy up" style={{ transform: "translate(1004px, 625px)" }}><tspan x="6.459" y="12">Took the offer to </tspan><tspan x="13.428" y="27">start a branding studio</tspan></text>
                                    <text className="cls-1 milestone-copy up" style={{ transform: "translate(1238px, 572px)" }}><tspan x="16.682" y="12">Learnt true meanings of </tspan><tspan x="20.686" y="27">Design and Leadership</tspan></text>
                                    <text className="cls-1 milestone-copy up" style={{ transform: "translate(1393px, 645px)" }}><tspan x="0" y="12">Pandemic hit </tspan><tspan x="0" y="27">the studio</tspan></text>
                                    <text className="cls-1 milestone-copy down" style={{ transform: "translate(1347px, 853px)" }}><tspan x="13.511" y="12">Loss of heart </tspan><tspan x="32.877" y="27">and vision</tspan></text>
                                    <text className="cls-1 milestone-copy down" style={{ transform: "translate(1478px, 1120px)" }}><tspan x="24.493" y="12">#@! d3$p41r-tym</tspan><tspan x="47.29" y="27">42#0000</tspan></text>
                                    <text className="cls-2 milestone-copy up" style={{ transform: "translate(1780px, 493px)" }}><tspan x="0" y="0">Heart resurfaces</tspan></text>

                                    <path id="Path_420" data-name="Path 422" d="M15557.5-9759.573s62.12-10.959,98.459,0,21.149,43.838,46.9,43.838,130.127-105.527,147.4-60.078c5.361,38.076,15.216,97.826,62.645,96.608s103.276-136.515,189.72-135.163c85.645,3.083,125.737-52.344,232.443-57.331,41.941,0,44.207,74.486,88.716,74.378s-32.951,162.286,20.328,159.518,45.637,270.217,124.08,267.161c80.137,1.338,62.936-512.339,303.764-599.733" transform="translate(-15014.638 10482.453)" fill="none" stroke="#17274d" strokeLinecap="round" strokeWidth="3" strokeDasharray={2260} strokeDashoffset={2260} />


                                    <rect className="cls-5 milestone-dot" width="11" height="11" rx="5.5" transform="translate(537.617 717.609)" />
                                    <rect className="cls-6 milestone-dot" width="9" height="9" rx="4.5" transform="translate(678.617 762.609)" />
                                    <rect className="cls-7 milestone-dot" width="13" height="12" rx="6" transform="translate(815.617 690.609)" />
                                    <rect className="cls-8 milestone-dot" width="13" height="13" rx="6.5" transform="translate(890.617 797.609)" />
                                    <rect className="cls-4 milestone-dot" width="10" height="9" rx="4.5" transform="translate(1074.617 664.609)" />
                                    <rect className="cls-9 milestone-dot" width="12" height="11" rx="5.5" transform="translate(1316.617 606.609)" />
                                    <rect className="cls-7 milestone-dot" width="10" height="10" rx="5" transform="translate(1398.617 679.609)" />
                                    <rect className="cls-10 milestone-dot" width="11" height="10" rx="5" transform="translate(1424.617 839.609)" />
                                    <rect className="cls-5 milestone-dot" width="11" height="11" rx="5.5" transform="translate(1545.617 1106.609)" />
                                    <rect className="cls-11 milestone-dot" width="13" height="13" rx="6.5" transform="translate(1851.617 505.609)" />
                                </g>
                            </svg>

                        </div>
                        <div className="about-journey-practice">
                            <div className="about-journey-practice-icon">
                                <img src={DangerFlagIcon}
                                    alt="🏴‍☠️"
                                    draggable="false"
                                    loading='lazy'
                                />
                            </div>
                            <div className="about-journey-practice-copy">
                                <p>Before I knew it, I had with me what one calls <span>A Practice</span></p>
                                <p>I have designed processes, led a team of creatives, found ways to hire such creatives, pitched difficult things to management, and created timeless visual designs.</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* I  N  F  L  U  E  N  C  E  S */}
                <section className="about-influences" id='influences' ref={influencesRef}>
                    <div className="about-influences-wrapper">
                        <div className="about-influences-icon">
                            <img src={CatIcon}
                                alt="🐈‍⬛"
                                draggable="false"
                                loading='lazy'
                            />
                        </div>
                        <p className="about-influences-copy">
                            <span className='influencer-name'>
                                <span>D</span>
                                <span>e</span>
                                <span>b</span>
                                <span>b</span>
                                <span>i</span>
                                <span>e</span>
                                <span>&nbsp;</span>
                                <span>M</span>
                                <span>i</span>
                                <span>l</span>
                                <span>l</span>
                                <span>m</span>
                                <span>a</span>
                                <span>n</span>
                                <span>&nbsp;</span>
                            </span>
                            <span className="influencer-copy">
                                <span>introduced me&nbsp;</span>
                                <span>to the world of&nbsp;</span>
                                <span>Design and Branding</span>
                            </span>
                        </p>

                        <div className="about-influences-icon">
                            <img src={TrainIcon}
                                alt="🚇"
                                draggable="false"
                                loading='lazy'
                            />
                        </div>
                        <p className="about-influences-copy"><span className='influencer-name'>Massimo and Lella Vignelli </span>
                            <span className="influencer-copy">taught me how to Design</span>
                        </p>

                        <div className="about-influences-icon">
                            <img src={LibertyIcon}
                                alt="🗽"
                                draggable="false"
                                loading='lazy'
                            />
                        </div>
                        <p className="about-influences-copy"><span className='influencer-name'>Alina Wheeler and Marty Neumier </span>
                            <span className="influencer-copy">the beauty of well-done brands</span>
                        </p>

                        <div className="about-influences-icon">
                            <img src={LadderIcon}
                                alt="🪜"
                                draggable="false"
                                loading='lazy'
                            />
                        </div>
                        <p className="about-influences-copy"><span className='influencer-name'>Al and Laura Ries </span>
                            <span className="influencer-copy">real differentiation</span>
                        </p>

                        <div className="about-influences-icon">
                            <img src={JapanIcon}
                                alt="🗾"
                                draggable="false"
                                loading='lazy'
                            />
                        </div>
                        <p className="about-influences-copy"><span className='influencer-name'>Juran </span>
                            <span className="influencer-copy">possibility for quality planning</span>
                        </p>

                    </div>

                    <div className="influences-art">
                        <div className="influences-art-group influences-art-group-1">
                            <img src={JohnArt} alt="" id='john' draggable='false' loading='lazy' />
                            <img src={DagnyArt} alt="" id='dagny' draggable='false' loading='lazy' />
                            <img src={DanArt} alt="" id='dan' draggable='false' loading='lazy' />
                            <img src={PaulArt} alt="" id='paul' draggable='false' loading='lazy' />
                            <img src={ReidArt} alt="" id='reid' draggable='false' loading='lazy' />
                            <img src={EssayArt} alt="" id='essay' draggable='false' loading='lazy' />
                            <img src={DieterArt} alt="" id='dieter' draggable='false' loading='lazy' />
                        </div>

                        <div className="influences-art-group influences-art-group-2">
                            <img src={MontaigneArt} alt="" id='montaigne' draggable='false' loading='lazy' />
                            <img src={RichardArt} alt="" id='richard' draggable='false' loading='lazy' />
                            <img src={HansArt} alt="" id='hans' draggable='false' loading='lazy' />
                            <img src={BasecampArt} alt="" id='basecamp' draggable='false' loading='lazy' />
                        </div>

                        <div className="influences-art-group influences-art-group-3">
                            <img src={JaiminArt} alt="" id='jaimin' draggable='false' loading='lazy' />
                            <img src={TagoreArt} alt="" id='tagore' draggable='false' loading='lazy' />
                            <img src={SethArt} alt="" id='seth' draggable='false' loading='lazy' />
                            <img src={GoogleArt} alt="" id='google' draggable='false' loading='lazy' />
                            <img src={CaseyArt} alt="" id='casey' draggable='false' loading='lazy' />
                        </div>
                    </div>
                </section>

                <section className="about-outro" id='outro' ref={outroRef}>
                    <div className="about-outro-wrapper">
                        <div className="about-outro-copy">
                            <p>In 2017&nbsp;<img src={SatelliteIcon}
                                alt="🛰️"
                                draggable="false"
                                loading='lazy'
                            />on the way to my Engineering Mathematics exam, I&nbsp;heard Steve Jobs on Soundcloud&nbsp;<img src={EducationIcon}
                                    alt="🎓"
                                    draggable="false"
                                    loading='lazy'
                                /> talk about doing great work and that the only way to do great work is to love what you do&nbsp;
                                <img src={FleurIcon}
                                    alt="⚜️"
                                    draggable="false"
                                    loading='lazy'
                                />
                                &nbsp;Since then, I have diverged from the well-trodden path&nbsp;
                                <img src={LogIcon}
                                    alt="🪵"
                                    draggable="false"
                                    loading='lazy'
                                />
                                &nbsp;In the years that followed, I have learnt a lot from the great masters and helped many others to do the same&nbsp;
                                <img src={TeapotIcon}
                                    alt="🫖"
                                    draggable="false"
                                    loading='lazy'
                                />
                            </p>
                        </div>
                        <div className="about-outro-filter">
                            <p>
                                <span className="line line-before"></span>
                                Intuition has been my sole filter
                                <span className="line line-after"></span>
                            </p>
                            <p>
                                Using which I have created Logos, Names, Interfaces, <Link to="/work/slangbusters">an&nbsp;entire Branding studio</Link> even.
                            </p>
                        </div>
                        <div className="about-footer">
                            <p><span>At the end of it all, action is what counts so </span><Link to="/work">browse my works here.</Link><span> Or you can directly </span><Link to="/contact?r=about">get in touch.</Link></p>
                            <span className='scroll-text'>
                                Scroll
                                <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M11 5v11.17l-4.88-4.88c-.39-.39-1.03-.39-1.42 0-.39.39-.39 1.02 0 1.41l6.59 6.59c.39.39 1.02.39 1.41 0l6.59-6.59c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L13 16.17V5c0-.55-.45-1-1-1s-1 .45-1 1z" /></svg>
                            </span>
                        </div>
                    </div>
                </section>


            </div>

            <Footer />

        </Styleswrapper>
    );
}

export default About;
